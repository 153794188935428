import React, { useContext, useState, useRef, useMemo, useEffect, useCallback } from 'react'
import NextLink from 'next/link'
import {
  MenuIcon,
  Button,
  Link,
  Grid,
  styled,
  MenuItem,
  styledComponent,
  Box,
  SettingsRoundedIcon,
  ExitToAppOutlinedIcon,
  LockOutlined,
  PersonOutlinedIcon,
  FormControl,
  Select,
  Image,
  Menu
} from '@hermes/web-components'
import { useRouter } from 'next/router'
import NextImage from 'next/image'
import useClickOutside from '../../hooks/useClickOutside'
import useTranslateMessage from '../../hooks/useTranslateMessage'

import { AppDataContext } from '../../providers/AppData'
import { getProviderUrl } from '../../utils/url'
import getCountryByLocale from '../../utils/getCountryByLocale'
import { GridHidden } from '../GridHidden'
import getConfigVariable from '../../utils/getConfigVariable'
import { TENANTS } from '../../constants/global'
import { TEST_IDS } from '../../tests/constants'
import apiRequest from '../../api/request'
import HeaderMenu from './Menu'

const internalApiUrl = getConfigVariable('INTERNAL_API_URL')
const stage = getConfigVariable('STAGE')

const textColor = 'primary.main'

const LogoLink = styled('a')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 32
  }
}))

const StyledMenuItem = styledComponent(MenuItem, {
  shouldForwardProp: (prop: string) => !['active'].includes(prop)
})<{ active?: boolean }>(({ theme, active }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.light
  },
  background: active ? theme.palette.background.default : theme.palette.common.white,
  fontSize: `${theme.typography.body2.fontSize} !important`,
  color: theme.palette.text.primary,
  justifyContent: 'space-between',
  spacing: theme.spacing(1.75, 2),
  minWidth: 200,
  position: 'relative',
  padding: 0,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'block'
  }
}))

const StyledSelect = styledComponent(Select, {
  shouldForwardProp: (prop: string) => !['isDarkVariant'].includes(prop)
})<{ isDarkVariant?: boolean }>(({ isDarkVariant }) => ({
  ...(isDarkVariant
    ? {
        '.MuiSelect-icon': {
          color: '#fff !important'
        },
        '.MuiSelect-select': {
          color: '#fff !important'
        }
      }
    : {})
}))

const StyledLink = styledComponent(Link, {
  shouldForwardProp: (prop) => prop !== 'isIconed'
})<{ isIconed?: boolean }>(({ theme, isIconed }) => ({
  textDecoration: 'none',
  color: 'inherit',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: isIconed ? 'flex-start' : 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.75, 2)
}))

const StyledIconButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isDarkVariant'
})<{ isDarkVariant?: boolean }>(({ theme, isDarkVariant }) => ({
  width: 64,
  height: '100%',
  background: isDarkVariant ? theme.palette.text.darkNavy : theme.palette.common.white,
  borderRadius: 'initial',
  color: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.secondary.light
  },
  '&:active': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  '&:focus-within, &:focus-visible, &:focus': {
    outline: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: isDarkVariant ? theme.palette.grey[200] : theme.palette.primary.main,
    color: '#fff'
  }
}))

type HeaderMenuItemProps = {
  title: string
  href?: string
  children?: HeaderMenuItemProps[]
  beforeIcon?: JSX.Element
}

const getLoginLink = (locale: string) => {
  // Redirect to en-ae instead of ar-ae until translations are added to the dashboard
  // Reference: https://gitlab.doctify.io/hermes/platform/-/issues/2755
  const tenantLocale = locale === TENANTS.AR_UAE ? TENANTS.EN_UAE : locale

  const suffix = stage === 'production' ? '' : `-${process.env.STAGE || 'development'}`
  return `https://dashboard${suffix}.doctify.com/${tenantLocale}`
}

const Login = () => {
  const translate = useTranslateMessage()
  const { locale } = useContext(AppDataContext)

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{ whiteSpace: 'nowrap', borderRadius: '24px', padding: '8px 24px' }}
      href={getLoginLink(locale)}
    >
      {translate('header.providerLogin')}
    </Button>
  )
}

const AuthorizedControls = ({ locale }: { locale: string }) => {
  const translate = useTranslateMessage()
  const router = useRouter()
  const [controlsAnchorEl, setControlsAnchorEl] = React.useState<null | HTMLElement>(null)
  const controlsIsOpen = Boolean(controlsAnchorEl)
  const handleOpenControls = (event: React.MouseEvent<HTMLButtonElement>) => {
    setControlsAnchorEl(event.currentTarget)
  }
  const handleCloseControls = () => {
    setControlsAnchorEl(null)
  }

  const handleLogout = () => {
    apiRequest(`${internalApiUrl}/${locale}/api/auth/logout`, {
      method: 'POST'
    }).then(() => {
      router.push(`/${locale}`)
    })
  }

  const getDashboardUrl = useCallback(() => {
    const localeWithoutArUae = locale === TENANTS.AR_UAE ? TENANTS.EN_UAE : locale

    switch (stage) {
      case 'staging': {
        return `https://dashboard-staging.doctify.com/${localeWithoutArUae}/dashboard/home`
      }
      case 'development': {
        return `https://dashboard-development.doctify.com/${localeWithoutArUae}/dashboard/home`
      }
      default: {
        return `https://dashboard.doctify.com/${localeWithoutArUae}/dashboard/home`
      }
    }
  }, [locale])

  return (
    <Box display="flex" alignItems="center">
      <Link href={getDashboardUrl()} sx={{ mr: 3, textDecoration: 'none' }}>
        {translate('header.back_to_dashboard')}
      </Link>
      <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
        <Button
          id="account-controls-button"
          variant="ghost"
          sx={{
            '&:hover': { background: 'transparent' }
          }}
          onClick={handleOpenControls}
          disableElevation
        >
          <SettingsRoundedIcon fontSize="small" sx={{ color: textColor }} />
        </Button>
        <Menu
          id="account-controls-menu"
          anchorEl={controlsAnchorEl}
          open={controlsIsOpen}
          onClose={handleCloseControls}
          MenuListProps={{
            'aria-labelledby': 'account-controls-button'
          }}
        >
          <MenuItem
            sx={{
              textDecoration: 'none',
              fontSize: 15,
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={handleLogout}
          >
            {translate('log_out')}
            <ExitToAppOutlinedIcon sx={{ fontSize: 18, ml: 1 }} />
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  )
}

const routeWithDarkTheme = [
  '/specialist/[slug]/claim-your-profile',
  '/specialist/[slug]/beanspruchen-sie-ihr-profil',
  '/specialist/[slug]/claim-your-profile-ar',
  '/specialist/[slug]/auto-journey'
]

const uaeTenants = {
  'en-ae': { title: 'EN', image: '/hermes-static/images/uk_flag.webp' },
  'ar-ae': { title: 'AR', image: '/hermes-static/images/ar_flag.png' }
}

const Header = () => {
  const menuContainer = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [originWithLang, setOriginWithLang] = useState('')
  const translate = useTranslateMessage()
  const { locale, userName, tenantSettings, isAuthorized } = useContext(AppDataContext)
  const providerLink = getProviderUrl(locale)

  const onOpenMenu = () => setMenuOpen(true)
  const onClose = () => setMenuOpen(false)

  useEffect(() => {
    setOriginWithLang(`${window.location.origin}/${locale === 'ar-ae' ? 'en-ae' : locale}`)
  }, [locale])

  const router = useRouter()

  const isDarkVariant = routeWithDarkTheme.includes(router.route)

  useClickOutside(menuContainer, onClose)

  const isSearchPage = useMemo(() => router.asPath.includes('/find/'), [router.asPath])

  const providerItems = useMemo(
    () => [
      {
        title: 'header.findSpecialist',
        href: `${originWithLang}/find/all/${getCountryByLocale(locale)}/specialists`,
        testId: 'header-main-menu-FindProvider-findSpecialist'
      },
      {
        title: 'header.findPractice',
        href: `${originWithLang}/find/all/${getCountryByLocale(locale)}/practices`,
        testId: 'header-main-menu-FindProvider-findPractice'
      },
      locale === 'uk'
        ? {
            title: 'header.findDentist',
            href: `${originWithLang}/find/dentistry/${getCountryByLocale(locale)}/practices`,
            testId: 'header-main-menu-FindProvider-dentistry'
          }
        : { title: undefined },
      locale === 'uk'
        ? {
            title: 'header.findCareHome',
            href: `${originWithLang}/find/health-and-social-care/${getCountryByLocale(locale)}/practices`,
            testId: 'header-main-menu-FindProvider-healthCare'
          }
        : { title: undefined }
    ],
    [locale, originWithLang]
  )

  const getLogoLocale = () => {
    if (router.locale === 'ar-ae') {
      return 'en-ae'
    }
    return locale
  }

  const getTrustUrl = () => {
    switch (router.locale) {
      case 'de': {
        return `/${locale}/about/vertrauen`
      }
      case 'de-at': {
        return `/${locale}/about/vertrauen`
      }
      case 'ar-ae': {
        return '/en-ae/about/trust'
      }
      default: {
        return `/${locale}/about/trust`
      }
    }
  }

  const getBlogUrl = () => {
    switch (router.locale) {
      case 'au': {
        return `/${locale}/blog/category/for-patients`
      }
      case 'de-at': {
        return `/${locale}${translate('doctify.blog.href.at')}`
      }
      case 'ar-ae': {
        return '/en-ae/blog/categories/for-patients'
      }
      default: {
        return `/${locale}${translate('doctify.blog.href')}`
      }
    }
  }

  const getAboutUrl = () => {
    if (router.locale === 'ar-ae') {
      return '/en-ae/about'
    }
    return `/${locale}/about`
  }

  const headerMenuItems = useMemo(() => {
    const unathorizedHeaderMenuItems = [
      { title: 'header.about', href: getAboutUrl(), testId: 'header-main-menu-About' },
      { title: 'header.trust', href: getTrustUrl(), testId: 'header-main-menu-Trust' },
      {
        title: 'header.findProvider',
        children: providerItems,
        testId: 'header-main-menu-FindProvider'
      },
      {
        title: 'header.other_sites',
        testId: 'header-main-menu-OtherSites',
        children: [
          { title: 'header.sites.uk', href: '/uk', testId: 'header-main-menu-OtherSites-UK' },
          { title: 'header.sites.uae', href: '/en-ae', testId: 'header-main-menu-OtherSites-AE' },
          { title: 'header.sites.at', href: '/de-at', testId: 'header-main-menu-OtherSites-AT' },
          { title: 'header.sites.de', href: '/de', testId: 'header-main-menu-OtherSites-DE' },
          { title: 'header.sites.au', href: '/au', testId: 'header-main-menu-OtherSites-AU' },
          { title: 'header.sites.ie', href: '/ie', testId: 'header-main-menu-OtherSites-IE' }
        ]
      },
      {
        title: 'header.blog',
        testId: 'header-main-menu-Blog',
        href: getBlogUrl()
      }
    ]

    const authorizedHeaderMenuItems = [
      {
        href: `${tenantSettings?.dashboardUrl}/dashboard/home`,
        title: userName || '',
        beforeIcon: <PersonOutlinedIcon />
      },
      {
        href: `${tenantSettings?.dashboardUrl}/change-password`,
        title: translate('change_password'),
        beforeIcon: <LockOutlined />
      }
    ]

    return [
      ...unathorizedHeaderMenuItems.map(({ title, ...otherProps }) => ({ title: translate(title), ...otherProps })),
      ...(isAuthorized ? authorizedHeaderMenuItems : [])
    ]
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, providerItems]) as HeaderMenuItemProps[]

  return (
    <Grid
      container
      data-testid={TEST_IDS.HEADER_FIND_PAGE}
      sx={{
        backgroundColor: isDarkVariant ? 'text.darkNavy' : 'common.white',
        height: { sm: 64, xs: 48 },
        pl: { sm: 2.5, xs: 0 },
        pr: 2,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)'
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item display="flex" height="100%" alignItems="center" xs={6}>
        <StyledIconButton
          aria-label="Main menu"
          data-testid={TEST_IDS.HEADER_MAIN_MENU}
          sx={{
            mr: 4.5,
            height: '100%',
            cursor: 'pointer',
            position: 'relative'
          }}
          isDarkVariant={isDarkVariant}
          onClick={onOpenMenu}
          disableRipple
        >
          <MenuIcon pathFill={isDarkVariant ? '#2B59E0' : 'currentColor'} />
          <HeaderMenu isOpen={menuOpen} menuContainerRef={menuContainer} headerMenuItems={headerMenuItems} />
        </StyledIconButton>
        <NextLink href={'/'} locale={getLogoLocale()} passHref>
          <LogoLink aria-label="Main Menu Logo Link">
            <NextImage
              src={`/hermes-static/logo${isDarkVariant ? '-light' : ''}.svg`}
              alt="Doctify logo"
              width={120}
              height={32}
              layout="fixed"
              priority
            />
          </LogoLink>
        </NextLink>
      </Grid>
      <Grid item container alignItems="center" justifyContent="flex-end" xs={6} spacing={5}>
        {!isSearchPage && Object.keys(uaeTenants).includes(locale) && (
          <Grid item>
            <FormControl>
              <StyledSelect isDarkVariant={isDarkVariant} defaultValue={locale}>
                {Object.entries(uaeTenants).map(([tenant, { title, image }]) => (
                  <StyledMenuItem
                    key={`${tenant}-${title}`}
                    value={tenant}
                    sx={{
                      minWidth: 120,
                      height: 50
                    }}
                  >
                    <StyledLink
                      href={`/${tenant}${router.asPath}`}
                      sx={{ justifyContent: 'space-around', padding: 0, paddingRight: 5 }}
                    >
                      {title}
                      <Image
                        src={image}
                        alt="flag"
                        width={24}
                        height={24}
                        sx={{ borderRadius: 10, display: 'block' }}
                      />
                    </StyledLink>
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
        )}
        <GridHidden wrap="nowrap" item container alignItems="center" gap="32px" justifyContent="end" xs={6} down="sm">
          <Grid item>
            <Box>
              <Link
                href={`/${locale === 'ar-ae' ? 'en-ae' : locale}${providerLink}`}
                sx={{ textDecoration: 'none', color: isDarkVariant ? 'white' : 'inherit', whiteSpace: 'nowrap' }}
              >
                {translate('header.for_providers')}
              </Link>
            </Box>
          </Grid>
          <Grid item>{isAuthorized ? <AuthorizedControls locale={locale} /> : <Login />}</Grid>
        </GridHidden>
      </Grid>
    </Grid>
  )
}

export default Header
