import { FC, useContext, useRef, useState, RefObject } from 'react'
import { Box, MenuList, useMediaQuery, useTheme } from '@hermes/web-components'
import { GridHidden } from '../../GridHidden'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../providers/AppData'
import useClickOutside from '../../../hooks/useClickOutside'
import ChevronRightIcon from '../../Icons/ChevronRightIcon'
import { StyledLink, StyledMenuItem } from './styles'
import { TENANTS } from '../../../constants/global'
import getConfigVariable from '../../../utils/getConfigVariable'
import { TEST_IDS } from '../../../tests/constants'

interface HeaderMenuItemProps {
  title: string
  href?: string
  children?: HeaderMenuItemProps[]
  beforeIcon?: JSX.Element
  testId?: string
}

const HeaderMenuItem: FC<HeaderMenuItemProps> = ({ title, href, children, beforeIcon, testId }) => {
  const translate = useTranslateMessage()
  const theme = useTheme()
  const { isRtl } = useContext(AppDataContext)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const menuContainer = useRef<HTMLLIElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const onOpenMenu = () => setMenuOpen(!menuOpen)
  const onClose = () => setMenuOpen(false)
  useClickOutside(menuContainer, onClose)

  const hasSubMenu = !!children?.length

  const linkProps = href ? { href } : { component: 'div' }

  return (
    <StyledMenuItem
      ref={menuContainer}
      active={menuOpen}
      data-testid={testId}
      onClick={hasSubMenu ? onOpenMenu : undefined}
    >
      <StyledLink isIconed={!!beforeIcon} {...linkProps}>
        {!!beforeIcon && <Box sx={{ mr: 0.5, color: 'primary.main' }}>{beforeIcon}</Box>}
        {decodeURIComponent(title)}
        {hasSubMenu && (
          <ChevronRightIcon
            reflectIfRtl
            sx={{
              fontSize: '14px',
              color: 'primary.main',
              ...(isRtl
                ? {
                    // eslint-disable-next-line no-nested-ternary
                    transform: isMobile
                      ? menuOpen
                        ? 'rotate(-90deg)'
                        : 'rotate(90deg)'
                      : menuOpen
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)'
                  }
                : {
                    // eslint-disable-next-line no-nested-ternary
                    transform: isMobile
                      ? menuOpen
                        ? 'rotate(-90deg)'
                        : 'rotate(90deg)'
                      : menuOpen
                      ? 'rotate(-180deg)'
                      : 'rotate(0deg)'
                  })
            }}
          />
        )}
      </StyledLink>
      {hasSubMenu && (
        <MenuList
          sx={{
            display: menuOpen ? 'block' : 'none',
            top: '0',
            boxShadow:
              '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04)',
            ...(isMobile
              ? {
                  position: 'relative',
                  left: 0,
                  zIndex: 999
                }
              : {
                  position: 'absolute',
                  left: '100%'
                })
          }}
        >
          {children.map(
            ({ title: childTitle, ...otherProps }, index) =>
              childTitle && (
                <HeaderMenuItem
                  data-testid={testId}
                  key={`sub-item-${childTitle}-${index}`}
                  title={translate(childTitle)}
                  {...otherProps}
                />
              )
          )}
        </MenuList>
      )}
    </StyledMenuItem>
  )
}

interface HeaderMenuProps {
  isOpen: boolean
  menuContainerRef: ((instance: HTMLUListElement | null) => void) | RefObject<HTMLUListElement> | null | undefined
  headerMenuItems: HeaderMenuItemProps[]
}

const getLoginLink = (locale: string) => {
  // Redirect to en-ae instead of ar-ae until translations are added to the dashboard
  // Reference: https://gitlab.doctify.io/hermes/platform/-/issues/2755
  const tenantLocale = locale === TENANTS.AR_UAE ? TENANTS.EN_UAE : locale
  const stage = getConfigVariable('STAGE')

  const suffix = stage === 'production' ? '' : `-${process.env.STAGE || 'development'}`
  return `https://dashboard${suffix}.doctify.com/${tenantLocale}`
}

const HeaderMenu: FC<HeaderMenuProps> = ({ menuContainerRef, headerMenuItems, isOpen }) => {
  const translate = useTranslateMessage()
  const { locale } = useContext(AppDataContext)

  return (
    <MenuList
      ref={menuContainerRef}
      data-testid={TEST_IDS.HEADER_MAIN_MENU_OPENED}
      sx={{
        display: isOpen ? 'block' : 'none',
        position: 'absolute',
        top: '100%',
        backgroundColor: '#FFF',
        left: '0',
        boxShadow: '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04)'
      }}
    >
      {headerMenuItems.map((item) => (
        <HeaderMenuItem key={`menu-${item.title}`} {...item} testId={item.testId} />
      ))}
      <GridHidden up="sm">
        <HeaderMenuItem key="menu-login" title={translate('header.providerLogin')} href={getLoginLink(locale)} />
      </GridHidden>
    </MenuList>
  )
}

export default HeaderMenu
